import React from 'react';
import NavBar from 'components/Navbars/Navbar';
import Solutions from './Solutions';
import Products from './Products';
import LeaderShip from './LeaderShip';
import ContactUs from './ContactUs';
import CopyRight from 'components/Footers/CopyRight';
import AboutSlider from 'components/Headers/AboutSlider';





const AboutUs = () => {
    document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });
    return (
      <>
        <NavBar />
        <AboutSlider/>
        <div className="main">
            <Solutions/>
            <Products/>
            <LeaderShip/>
            <ContactUs/>
            <CopyRight/>
        </div>
        </>
    )
}

export default AboutUs