
import React from "react";

// reactstrap components

// core components
import NavBar from "components/Navbars/Navbar.js";
import SliderSection from "components/Headers/SliderSection.js";
import CopyRight from "components/Footers/CopyRight.js";

// index sections
// import ContentSection from "views/index-sections/ContentSection";
import ContactUs from "./index-sections/ContactUs";
import WhyVastavIT from "./index-sections/WhyVastavIT";


function Index() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });
  return (
    <>
      <NavBar />
      <SliderSection />
      <div className="main">
        <WhyVastavIT/>
        <ContactUs/>      
        <CopyRight />
      </div>
    </>
  );
}

export default Index;
