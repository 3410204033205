
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Navigate,  Routes } from "react-router-dom";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/vastavit-kit.scss?v=1.3.0";

import Index from "views/Index.js";
import AboutUs from "views/index-sections/AboutUs";


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter> 
    <Routes>
      <Route path="/" exact element={<Index />} />
      <Route path="/about" exact element={<AboutUs />} />
      <Route path="*" element={<Navigate to="/index" replace />} />
    </Routes>
  </BrowserRouter>
);
