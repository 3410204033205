
/*eslint-disable*/
import React from "react";

// reactstrap components
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// core components

function SliderSection() {
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false
  };
  return (
    <>
      <div className="page-header" id="home">
        <Slider {...settings}>
          <div className="sliderimg1">
            <div className="sliderTitle">
              <div className="col-lg-6 titleContent">
                <h1 className="presentation-title text-center">
                  Your Journey to IT Perfection Starts Here
                </h1>
                <h2 className="presentation-subtitle text-center">
                  Building a relationship between IT companies & experts
                </h2>
              </div>
            </div>
          </div>
          <div className="sliderimg2">
            <div className="sliderTitle ">
              <div className="col-lg-6 titleContent">
                <h1 className="presentation-title text-center">Future-Proof Your Business with Us</h1>
                <h2 className="presentation-subtitle text-center">
                Adopt scalable and adaptable IT solutions that ensure your business stays competitive and resilient.
                </h2>
              </div>
            </div>
          </div>
          <div className="sliderimg3">
            <div className="sliderTitle ">
              <div className="col-lg-6 titleContent">
                <h1 className="presentation-title text-center">Driving Efficiency with Innovative Solutions</h1>
                <h2 className="presentation-subtitle text-center">
                Boost productivity and streamline your operations with our state-of-the-art IT solutions and services
                </h2>
              </div>
            </div>
          </div>
        </Slider>
      </div>

      {/* <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(" + require("assets/img/antoine-barres.jpg") + ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="title-brand">
              <h1 className="presentation-title">
              Your Journey to IT Perfection Starts Here
              </h1>
              
            </div>
            <h2 className="presentation-subtitle text-center">
            Building a relationship between IT companies & experts
            </h2>
          </Container>
        </div>
      </div> */}
    </>
  );
}

export default SliderSection;
