
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Container } from "reactstrap";

function CopyRight() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row className="justify-content-center py-5">
          <span>© 2024 Vastav IT Solutions - All Rights Reserved</span>
        </Row>
      </Container>
    </footer>
  );
}

export default CopyRight;
