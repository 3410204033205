import React from 'react';
import {
    Container,
    // Row
} from "reactstrap";

const Solutions = () => {
    return (
        <div id='solutions'>
            <Container>
                <div className='solution_sec'>
                    <div className=" highlight_content">
                        <h2 className="font-weight-normal pb-4 text-orange">Our Solutions</h2>
                        <ol>
                            <li>
                            Our Collection Management System offers a comprehensive solution for organizing, tracking, and analyzing your account(s) information with unmatched efficiency. Featuring an intuitive interface, the system allows for detailed cataloging, real-time VOIP Voice Loggers, and dynamic reporting, ensuring seamless management of your collection whether it’s a Start up or an Established Organization, With powerful search capabilities, secure access controls, and integration options, our CMS application makes the collection process easier and accountable.
                            </li>
                            <li>
                                Website Development and Wesite Hosting using Latest Technologies which include Flat designs, SPA, React, Angular, Node JS, Tailwind CSS, Responsive.
                            </li>
                        </ol>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Solutions