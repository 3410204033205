import React from 'react'
import { Container } from 'reactstrap'

const AboutSlider = () => {
  return (
    <div className='aboutBanner'>
        <Container className='h-100'>
            <div className='sliderText'>
                <h1>About Us</h1>
            </div>
        </Container>
    </div>
  )
}

export default AboutSlider