import React from 'react'
import { Container } from 'reactstrap';
import avatar from '../../assets/img/man.png'

const LeaderShip = () => {
  return (
    <div>
        <Container>
        <h2 className="text-center font-weight-normal pb-4 text-orange">Our Leadership</h2>
            <div className='about_sec'>
                <img src={avatar} alt="Vastav It Leadership"/>
                <div>
                    <h4 className='mb-4'>Mr. Ravi Prakash <span>CEO</span></h4>
                    <p>
                    Having 15 years of strong experience in Banking, Financial, Debt Collection, Mobile Application developments, worked in High Level designations in leading business houses in both Malaysia and India
Knows the entire Malaysian debt collection market from inside out.
                    </p>
                </div>
            </div>
        </Container>
    </div>
  )
}

export default LeaderShip