import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from "reactstrap";

const ContactUs = () => {
  return (
    <div className='contact_sec' id='contact'>
      <Container>
        <h2 className="font-weight-normal pb-4 text-orange">Contact Us</h2>
        <div className='contact_wrp'>
          <div className='mb-4 text-white d-flex align-items-center icon_wrp'>
            <i class="fa fa-map-marker" aria-hidden="true"></i>
            <div>
              <h5 className='m-0'>Address</h5>
              <p>Brick Fields, Kuala Lumpur, Malaysia 50470</p>
            </div>
          </div>
          <div className=' mb-4 text-white d-flex align-items-center icon_wrp'>
            <i class="fa fa-envelope" aria-hidden="true"></i>
            <div>
            <h5 className='m-0'>Email</h5>
            <Link className=' font-weight-normal mailText' to="mailto:contact@vastavit.com
">contact@vastavit.com</Link>
  </div>
          </div>
          <div className='text-white d-flex align-items-center icon_wrp'>
            <i class="fa fa-phone-square" aria-hidden="true"></i>
            <div>
            <h5 className='mb-0'>Contact No.</h5>
            <Link className='text-white font-weight-normal contactNumber' target='_blank' to='https://wa.me/+601116621224'>+6 01116621224</Link>
            </div>
          </div>
        </div>

      </Container>
    </div>
  )
}

export default ContactUs