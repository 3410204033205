import React from 'react';
import { Container } from 'reactstrap';
import image from "../../assets/img/img1.png";
import image2 from "../../assets/img/CMS-Screen.jpg";

const WhyVastavIT = () => {
    return (
        <div>
            <Container>
                <div className='whyVastavContent'>
                    <h2 className="font-weight-normal pb-4 text-orange">Why Vastav IT?</h2>
                    <p className='mb-4'>
                        Our Debt Management and Collection software solutions will empower organizations with efficient, compliant, and customizable software tools to optimize their debt management operations.
                    </p>
                    <ol className='pb-5'>
                        <li className='mb-3'>Comprehensive Debt Tracking: comprehensive platform to track and manage all stages of the debt lifecycle. It provides real-time visibility into each account’s status, payment history, and communication interactions.
                        </li>
                        <li className='mb-3'>
                            Automated Workflows: The software incorporates automated workflows that enable organizations to streamline routine processes. This includes automated payment reminders, status updates, and task assignments, reducing the need for manual intervention and improving efficiency.
                        </li>

                        <li className='mb-3'>Multi-Channel Communication: The software upports omni-channel communication, allowing organizations to interact with debtors through various channels, such as emails, SMS, and even integrated phone systems. This improves communication effectiveness and enhances debtor engagement.
                        </li>
                        <li className='mb-3'>
                            Customization and Configuration: Recognizing the unique needs of different organizations, the software is developed to offer a high degree of customization. Users can tailor workflows, communication templates, and reporting dashboards to align with their specific strategies and compliance requirements.
                        </li>
                        <li className='mb-3'>
                            Advanced Analytics and Reporting: The software includes advanced analytics and reporting tools, offering actionable insights into debt portfolio performance. This empowers decision-makers with the information they need to optimize strategies and drive improved collection outcomes.
                        </li>
                    </ol>

                    <div className="col-lg-7 m-auto">
                        <a class="lightbox" href="#cms">
                            <img className="img-fluid lightbox" src={image} alt="" />
                        </a>
                        <div className="lightbox-target" id="cms">
                            <img src={image2} alt="" />
                            <a className="lightbox-close" href="#"></a>
                        </div>
                    </div>
                </div>

            </Container>
        </div>
    )
}

export default WhyVastavIT